import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from './actions'
import SocketClient from './components/SocketClient'
import PrivateRoutes from './routes/PrivateRoutes'
import PublicRoutes from './routes/PublicRoutes'

function App({ socket }) {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const userInfo = useSelector((state) => state.user.user)
  const isButtonUser = useSelector((state) => state.app.isButtonUser)
  const isCategory = useSelector((state) => state.app.isCategory)
  const isCategoryMobile = useSelector((state) => state.app.isCategoryMobile)
  const subCategory = useSelector((state) => state.app.subCategory)
  const category = useSelector((state) => state.app.category)
  const advertisements = useSelector((state) => state.app.advertisements)
  const isModalMobilePhone = useSelector(
    (state) => state.app.isModalMobilePhone,
  )
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const [s, setS] = useState([])

  // useEffect(() => {
  //   setS(
  //     filtersAll.map((item, index) => ({
  //       ...item,
  //       i: index + 1,
  //     })),
  //   )
  // }, [filtersAll])
  //
  // console.log(s)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          actions.toggleCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        )
      })
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.allCategoryInfo())
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.allSwiperInfo())
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.allAdvertisementsInfo())
  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.userInfo())
    }
  }, [dispatch, isAuthenticated])

  const onButtonUser = () => {
    dispatch(actions.toggleShowUser(!isButtonUser))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleCategory(false))
    dispatch(actions.toggleSubCategory(null))
  }

  const onToggleCategoryMobile = () => {
    if (subCategory) {
      dispatch(actions.toggleSubCategory(null))
      dispatch(actions.toggleShowUser(false))
      dispatch(actions.toggleCategory(false))
    } else {
      dispatch(actions.toggleCategoryMobile(!isCategoryMobile))
      dispatch(actions.toggleShowUser(false))
      dispatch(actions.toggleCategory(false))
      dispatch(actions.toggleSubCategory(null))
    }
  }

  const onToggleCategory = () => {
    dispatch(actions.toggleCategory(!isCategory))
    dispatch(actions.toggleShowUser(false))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleSubCategory(null))
  }

  const onDisableAll = () => {
    dispatch(actions.toggleShowUser(false))
    dispatch(actions.toggleCategoryMobile(false))
    dispatch(actions.toggleCategory(false))
    dispatch(actions.toggleSubCategory(null))
  }

  if (isAuthenticated && userInfo) {
    return (
      <SocketClient
        user={userInfo}
        socket={socket}
        onDisableAll={onDisableAll}
        onButtonUser={onButtonUser}
        isButtonUser={isButtonUser}
        isCategoryMobile={isCategoryMobile}
        isCategory={isCategory}
        onToggleCategoryMobile={onToggleCategoryMobile}
        onToggleCategory={onToggleCategory}
        subCategory={subCategory}
        category={category}
      >
        {category && category.length > 0 && (
          <PrivateRoutes
            isPrivate={isAuthenticated}
            user={userInfo}
            onDisableAll={onDisableAll}
            onButtonUser={onButtonUser}
            isButtonUser={isButtonUser}
            isCategoryMobile={isCategoryMobile}
            isCategory={isCategory}
            onToggleCategoryMobile={onToggleCategoryMobile}
            onToggleCategory={onToggleCategory}
            category={category}
            advertisements={advertisements}
            subCategory={subCategory}
            isModalMobilePhone={isModalMobilePhone}
          />
        )}
      </SocketClient>
    )
  } else {
    return (
      <SocketClient
        user={null}
        socket={socket}
        onDisableAll={onDisableAll}
        onButtonUser={onButtonUser}
        isButtonUser={isButtonUser}
        isCategoryMobile={isCategoryMobile}
        isCategory={isCategory}
        onToggleCategoryMobile={onToggleCategoryMobile}
        onToggleCategory={onToggleCategory}
        subCategory={subCategory}
        category={category}
      >
        {category && category.length > 0 && (
          <PublicRoutes
            isPrivate={isAuthenticated}
            user={null}
            onDisableAll={onDisableAll}
            onButtonUser={onButtonUser}
            isButtonUser={isButtonUser}
            isCategoryMobile={isCategoryMobile}
            isCategory={isCategory}
            onToggleCategoryMobile={onToggleCategoryMobile}
            onToggleCategory={onToggleCategory}
            category={category}
            advertisements={advertisements}
            subCategory={subCategory}
            isModalMobilePhone={isModalMobilePhone}
          />
        )}
      </SocketClient>
    )
  }
}

export default App
