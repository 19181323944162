import React from 'react'
import {
  Switch,
  Redirect,
  BrowserRouter as Router,
  BrowserRouter,
} from 'react-router-dom'
import routesLik from '../../constants/routes.constants'
import omit from 'lodash-es/omit'
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes'
import { privateRouteConfig } from '../../routes'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import MobileMain from '../../components/MobileMain'
import ModalViewNumber from '../../components/ModalViewNumber'
import { useDispatch } from 'react-redux'

function PrivateRoutes({
  isPrivate,
  user,
  onButtonUser,
  isButtonUser,
  onDisableAll,
  isCategory,
  isCategoryMobile,
  onToggleCategoryMobile,
  onToggleCategory,
  category,
  advertisements,
  isModalMobilePhone,
}) {
  const dispatch = useDispatch()
  return (
    <>
      <BrowserRouter>
        <Router>
          <Header
            isPrivate={isPrivate}
            user={user}
            onDisableAll={onDisableAll}
            onButtonUser={onButtonUser}
            isButtonUser={isButtonUser}
            isCategory={isCategory}
            isCategoryMobile={isCategoryMobile}
            onToggleCategoryMobile={onToggleCategoryMobile}
            onToggleCategory={onToggleCategory}
            category={category}
            advertisements={advertisements}
          />
          <Switch>
            {privateRouteConfig.map((route) => (
              <RouteWithSubRoutes key={route.id} {...omit(route, 'id')} />
            ))}
            <Redirect to={routesLik.root} />
          </Switch>
          <Footer />
          {isCategoryMobile && (
            <MobileMain dispatch={dispatch} category={category} />
          )}
          {isModalMobilePhone && (
            <ModalViewNumber
              user={isModalMobilePhone.user}
              dispatch={dispatch}
              product={isModalMobilePhone}
            />
          )}
        </Router>
      </BrowserRouter>
    </>
  )
}
export default PrivateRoutes
